import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import ContentAndPicture from "../components/content-and-picture"

import globals from "../components/globals"

import ValueBox from "../components/valuebox"
import Schedule from "../components/schedule"
import TrustBox from "../components/trusbox"
import IIIStep from "../components/3step"
import Testimonials from "../components/testimonials"
import YoutubeBackground from "../components/youtube-background"
import { ContactForm, Download } from "../components/contactform"

import { Container, H2, P } from "../components/styledcomponents"

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  min-height: max(55vw, 300px);
  width: 100%;
  height: 100%;
  padding: 0;
`

const HeroTextContainer = styled.div`
  max-width: 500px;
  padding: 1em;
  border-radius: 8px;
  position: relative;
  margin: 3em 0 3em 10%;
  background-color: #00000080;
  backdrop-filter: blur(3px);
  @media (max-width: ${globals.media.mobile}) {
    margin-left: auto;
    margin-right: auto;
  }
`

const H1 = styled.h1`
  font-size: 1.6em;
  text-align: left;
  margin-bottom: 0.5em;
  color: ${globals.color.main};
  font-weight: bold;
  @media (max-width: ${globals.media.tablet}) {
    font-size: 1em;
  }
`

const Hr = styled.hr`
  border: solid ${globals.color.main};
  width: 4em;
  margin-left: 0;
  @media (max-width: ${globals.media.tablet}) {
    width: 2.5em;
    2px solid #F17915
  }
`
const MMAKids = ({ location, data }) => {
  return (
    <Layout pathname={location.pathname}>
      <SEO
        title=" Kampfsport in München, Feldmoching - Team Laurien & Alex"
        description=" Kampfsport in München - Nord: Kickboxen, BJJ, Grappling, MMA, Selbstverteidigung. Komm zum kostenlosen Probetraining!"
      />
      <YoutubeBackground
        videoId={"b3K_ZrzpJaA"}
        playerOptions={{
          end: 35,
        }}
      >
        <HeroContainer>
          <HeroTextContainer
            style={{
              position: "absolute",
              top: 0,
            }}
          >
            <H1 color="white"> Kampfsport in München </H1> <Hr />
          </HeroTextContainer>
        </HeroContainer>
      </YoutubeBackground>
      <ValueBox
        values={[
          {
            id: 1,
            value: <> Körperliche Fitness & Mentale Fitness </>,
          },
          {
            id: 2,
            value: "Stressabbau",
          },
          {
            id: 3,
            value: "Lösungsorientiertes Denken",
          },
          {
            id: 4,
            value: "Team Spirit",
          },
        ]}
      />
      <IIIStep
        service
        ltr
        columnsMobile="1fr"
        title="Unsere Kampfsportarten"
        pathname={location.pathname}
        values={[
          {
            id: "Brazilian Jiu Jitsu (BJJ)",
            img: data.sanfterYogaFlow,
            to: "/bjj-muenchen/",
          },
          {
            id: "Mixed Martial Arts (MMA)",
            img: data.dynYogaFlow,
            to: "/mma-kickboxen-muenchen/",
          },
        ]}
      />
      <ContentAndPicture
        h2="Warum Kampfsport?"
        h2Style={{
          textAlign: "center",
          margin: "auto",
          width: "fit-content",
        }}
        description={
          <P>
            Kampfsport, vor allem Mixed Martial Arts(MMA) und Brazilian Jiu
            Jitsu(BJJ), ist auf mentale und körperliche Ebene herausfordernd.
            Wie beim Schach versuchst Du Deinen Trainingspartner durch Technik
            und Strategie Schachmatt zu setzen.Das heißt, Kleinere und
            Schwächere können durch Technik, Größere und Stärkere zur Aufgabe
            zwingen.Daher ist Kampfsport für jedes Alter geeignet. <br />
            MMA und BJJ eignen sich deswegen sehr gut für diejenigen die
            Kampfsport zur Selbstverteidigung anfangen wollen.Abgesehen vom
            hohen Wert für die Selbstverteidigung und Selbstbehauptung, macht
            MMA & BJJ Hammer viel Spaß!Dukannst Dich richtig auspowern, fit
            werden, entspannen, Freunde finden, und Du lernst nützliche
            Fähigkeiten.Wenn Du weitere Herausforderungen suchst bieten wir auch
            die Möglichkeit an, an Wettkämpfen teilzunehmen.{" "}
          </P>
        }
      />
      <IIIStep
        service
        ltr
        columns="repeat(6, 1fr)"
        columnsTablet="repeat(4, 1fr)"
        columnsMobile="1fr"
        title="Unser Kampfsportangebot"
        pathname={location.pathname}
        values={[
          {
            id: (
              <>
                Brazilian Jiu Jitsu(BJJ) <br /> für Jugendliche & Erwachsene{" "}
              </>
            ),
            title: "Brazilian Jiu Jitsu (BJJ) für Jugendliche & Erwachsene",
            alt: "Brazilian Jiu Jitsu (BJJ) für Jugendliche & Erwachsene",
            img: data.bjj,
            to: "/bjj-muenchen/#jugendliche-und-erwachsene",
            gridColumn: "2/4",
            gridColumnTablet: "1/3",
          },
          {
            id: (
              <>
                Mixed Martial Arts(MMA) <br /> für Jugendliche & Erwachse{" "}
              </>
            ),
            title: "Mixed Martial Arts (MMA) für Jugendliche & Erwachse",
            alt: "Mixed Martial Arts (MMA) für Jugendliche & Erwachse",
            img: data.mma,
            to: "/mma-kickboxen-muenchen/#jugendliche-und-erwachsene",
            gridColumn: "4/6",
            gridColumnTablet: "3/5",
          },
          {
            id: (
              <>
                Brazilian Jiu Jitsu(BJJ) <br /> für Kinder ab 6 Jahren{" "}
              </>
            ),
            title: "Brazilian Jiu Jitsu (BJJ) für Kinder ab 6 Jahren",
            alt: "Brazilian Jiu Jitsu (BJJ) für Kinder ab 6 Jahren",
            img: data.bjjKids,
            to: "/bjj-muenchen/#kinder",
            gridColumn: "1/3",
            gridColumnTablet: "1/3",
          },
          {
            id: (
              <>
                Mixed Martial Arts(MMA) <br /> für Kindern ab 6 Jahren{" "}
              </>
            ),
            title: "Mixed Martial Arts (MMA) für Kinder ab 6 Jahren",
            alt: "Mixed Martial Arts (MMA) für Kinder ab 6 Jahren",
            img: data.asthanga,
            to: "/mma-kickboxen-muenchen/#kinder",
            gridColumn: "3/5",
            gridColumnTablet: "3/5",
          },
          {
            id: (
              <>
                Kleine Ninjas <br /> für Kindern ab 3 Jahren{" "}
              </>
            ),
            title: "Kleine Ninjas für Kinder ab 3 Jahren",
            alt: "Kleine Ninjas für Kinder ab 3 Jahren",
            img: data.ninjas,
            to: "/bjj-muenchen/#ninjas",
            gridColumn: "5/7",
            gridColumnTablet: "2/4",
          },
        ]}
      />
      <Schedule filter="Kampfsport" />
      <Container backgroundColor="transparent" padding="4em 0" />
      <TrustBox
        title="Unser Yoga- und Kampfsportangebot überzeugt:"
        values={[
          {
            id: 1,
            img: data.trust1,
            trust: "Kampfsportschule des Jahres 2020",
          },
          {
            id: 5,
            img: data.trust5,
            trust: "Yogalehrerausbildung in Indien von Lalit Kumar",
          },
          {
            id: 3,
            img: data.trust3,
            trust: "National und International erfolgreich",
          },
          {
            id: 6,
            img: data.trust6,
            trust: "BJJ Graduierung duch Cadu Francis",
          },
          {
            id: 4,
            img: data.trust4,
            trust: "Begeisterte Kunden",
          },
          {
            id: 2,
            img: data.trust2,
            trust: "IBJJF Zertifiziert",
          },
        ]}
      />{" "}
      <IIIStep
        title="Jede größere Änderung beginnt mit einem Schritt!"
        description="Egal, ob Du nach der Arbeit Stress abbauen, abnehmen, Dich in Form bringen oder Dich körperlich oder mental herausfordern möchtest, Team Laurien & Alex hilft Dir dabei, Dein Ziel zu erreichen."
        pathname={location.pathname}
        values={[
          {
            id: 1,
            img: data.step1,
            value: "Schritt 1 ",
            statement: "Buche Deine kostenlose Probestunde noch heute!",
          },
          {
            id: 2,
            img: data.step2,
            value: "Schritt 2",
            statement:
              "Habe Spaß beim Training mit dem Team & freue Dich auf fortlaufende Unterstützung.",
          },
          {
            id: 3,
            img: data.step3,
            value: "Schritt 3",
            statement:
              "Erfahre wie sich Deine körperliche und geistige Fitness verbessert!",
          },
        ]}
      />{" "}
      <Container backgroundColor="transparent">
        <H2 center noLine>
          Wir versprechen Dir, Du fühlst Dich jederzeit wilkommen!
        </H2>{" "}
      </Container>{" "}
      <Testimonials
        title="Das sagen unsere Mitglieder:"
        values={[
          {
            id: 4,
            review:
              "TLA gibt es nun mehr denn 2 Jahre. Mein Sohn (8 Jahre) ist seit der ersten Stunde BJJ dabei und immer noch Feuer und Flamme für diesen Kampfsport. Er hatte sich nie für irgendein Hobby interessiert, doch gleich nach der ersten kostenlosen Probestunde statt für ihn fest, das gefällt ihm, hier will er bleiben. Mittlerweile haben sich seine Skills dank der fürsorglichen und kompetenten Hinwendung durch Laurien und Alex so stark verbessert, dass er sogar auf Turnieren im Ausland Gold gewinnt. Wir, die Eltern, sind mittlerweile auch feste Mitglieder bei TLA und trainieren regelmäßig bei MMA und BJJ mit. Somit sind Laurien und Alex ein Gewinn für unsere ganze Familie geworden und wir möchten Sie nicht mehr missen!!!",
            reviewer: "Susanne R.",
            platform: "meunchen.de",
            img:
              "https://cdn.shortpixel.ai/spai/q_glossy+ret_img+to_webp/https://lh4.ggpht.com/-tjAsb2ibil4/AAAAAAAAAAI/AAAAAAAAAAA/RIE7jEoWFnU/s128-c0x00000000-cc-rp-mo-ba2/photo.jpg",
          },
          {
            id: 1,
            review:
              "Kleines, sauberes Gym mit familiärer Atmosphäre. Laurien und Alex machen ihren Job sauber und mit Herz. Sie gehen toll mit Kindern um, haben ein super Verhältnis zu den Jugendlichen und jeder wird als Freund empfangen und nicht als zahlender Kunde gesehen. Hier stimmt das Verhältnis zwischen Härte und Herz. Fachlich und technisch sind die zwei Top und ergänzen sich gut. Die Trainings sind super und am nächsten Tag weiß man, was man getan hat 👍🏻",
            reviewer: "Sebastian R.",
            platform: "google.de",
            img:
              "https://cdn.shortpixel.ai/spai/q_glossy+ret_img+to_webp/https://lh4.ggpht.com/-tjAsb2ibil4/AAAAAAAAAAI/AAAAAAAAAAA/RIE7jEoWFnU/s128-c0x00000000-cc-rp-mo-ba2/photo.jpg",
          },
          {
            id: 2,
            reviewer: "Nacht Glocke",
            img:
              "https://lh3.ggpht.com/-EF1fjB92uWs/AAAAAAAAAAI/AAAAAAAAAAA/6Yz6efcUG2g/s128-c0x00000000-cc-rp-mo/photo.jpg",
            review:
              "Tolle Kampfsportschule, Laurin und Alex sind mega sympathisch und sehr professionell. Die Kampfsportstunden machen richtig Spaß und Yoga ist auch super. Vor allem die Aktion im Sommer #yogaamfasaneriesee, bei der erfolgreich Geld für die Münchner Tafel gesammelt wurde. Große Empfehlung",
            platform: "google.de",
          },
          {
            id: 3,
            reviewer: "macmel 2",
            img:
              "https://lh4.ggpht.com/-BZVPNvS1X0I/AAAAAAAAAAI/AAAAAAAAAAA/VgwMgc_iE4Q/s128-c0x00000000-cc-rp-mo/photo.jpg",
            review:
              "Ein Super Gym!!! Echt tolle Leute und super Trainer mit Erfahrung, jedes mal lernt man was neues und wird im in seinen individuellen Stärcken aber auch Scheächen gestärkt. Kann man echt nur weiter empfehlen, wenn jemand ein neues Gym mit familiärer Atmosphäre sucht!",
            platform: "google.de",
          },
        ]}
      />{" "}
      <div>
        <ContactForm />
      </div>{" "}
      <Download image={data.magnet} />{" "}
    </Layout>
  )
}
export const query = graphql`{
  hero1: file(relativePath: {eq: "Kampfsport/Kampfsport-Muenchen.JPG"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  hero2: file(relativePath: {eq: "Kampfsport/BJJ-Muenchen-Flying-Armbar.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  hero3: file(
    relativePath: {eq: "Kampfsport/Kampfsport-Muenchen-Kids-Muay-Thai-Kick.JPG"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  hero4: file(
    relativePath: {eq: "Kampfsport/Kampfsport-Muenchen-MMA-Takedown-.JPG"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  hero5: file(
    relativePath: {eq: "Kampfsport/Kampfsport-Kids-Muenchen-Double-Leg-Takedown.JPG"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  sanfterYogaFlow: file(
    relativePath: {eq: "Kampfsport/BJJ-Muenchen-Overhead-Sweep.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(
        width: 900
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  dynYogaFlow: file(relativePath: {eq: "Kampfsport/MMA-Muenchen-body-kick.jpg"}) {
    childImageSharp {
      gatsbyImageData(
        width: 900
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  mma: file(relativePath: {eq: "Kampfsport/BJJ-Muenchen-MMA-Kickboxen.JPG"}) {
    childImageSharp {
      gatsbyImageData(
        width: 900
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  asthanga: file(
    relativePath: {eq: "Kampfsport/MMA-Kickboxen-Muenchen-Kicking.JPG"}
  ) {
    childImageSharp {
      gatsbyImageData(
        width: 900
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  bjj: file(relativePath: {eq: "Kampfsport/Kampfsport-Muenchen-BJJ.jpg"}) {
    childImageSharp {
      gatsbyImageData(
        width: 900
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  bjjKids: file(relativePath: {eq: "Kampfsport/Kampfsport-Kids-Muenchen-BJJ.JPG"}) {
    childImageSharp {
      gatsbyImageData(
        width: 900
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  ninjas: file(
    relativePath: {eq: "Kampfsport/Kampfsport-Muenchen-Kleine-Ninjas.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(
        width: 900
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  trust1: file(
    relativePath: {eq: "Kampfsportschule-des-Jahres-Team-Laurien-Alex.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(
        width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  trust2: file(relativePath: {eq: "IBJJF-Zerifikat-TLA-BJJ.JPG"}) {
    childImageSharp {
      gatsbyImageData(
        width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  trust3: file(relativePath: {eq: "Deutsche-Meister-BJJ-IBJJF-Kickboxen.jpg"}) {
    childImageSharp {
      gatsbyImageData(
        width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  trust5: file(
    relativePath: {eq: "Yogalehrerzertifikat-in-indien-von-Lalit-Kumar-Himalaya-Yoga-Valley.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(
        width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  trust4: file(relativePath: {eq: "Reviews-Team-Laurien-Alex.JPG"}) {
    childImageSharp {
      gatsbyImageData(
        width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  trust6: file(
    relativePath: {eq: "Laurien-and-alex-with-Cadu-Francis-purple-belt-graduation.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(
        width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  step1: file(
    relativePath: {eq: "Persoenliche-Unterstuetzung-beim-kostenlosen-Probetraining-TLA-Muenchen.JPG"}
  ) {
    childImageSharp {
      gatsbyImageData(
        width: 400
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  step2: file(relativePath: {eq: "Spass-beim-Training-im-Team-Muenchen.JPG"}) {
    childImageSharp {
      gatsbyImageData(
        width: 400
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  step3: file(
    relativePath: {eq: "Fitness-verbessern-team-laurien-alex-chaturanga-liegestuetz.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(
        width: 400
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  magnet: file(relativePath: {eq: "Cover_Bist_du_gestresst_atemuebungen.jpg"}) {
    childImageSharp {
      gatsbyImageData(
        width: 400
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
}
`
export default MMAKids
